body {
  margin: 0;

  background-color: black;
  background-image: url("https://images.unsplash.com/photo-1477346611705-65d1883cee1e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80");
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Disable highlighting text */
  /*-webkit-touch-callout: none; !* iOS Safari *!*/
  /*-webkit-user-select: none; !* Safari *!*/
  /*-khtml-user-select: none; !* Konqueror HTML *!*/
  /*-moz-user-select: none; !* Old versions of Firefox *!*/
  /*-ms-user-select: none; !* Internet Explorer/Edge *!*/
  /*user-select: none; !* Non-prefixed version, currently*/
  /*                                supported by Chrome, Edge, Opera and Firefox *!*/
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}